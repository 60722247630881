import { FlexBox } from '@vp/swan'
import classes from './PromoBar.module.scss'

interface PromoBarProps {
  locale: string
}

const PromoBar = ({ locale }: PromoBarProps) => {
  const promoMessage =
    locale === 'en-au'
      ? `You're all set to print! Get 20% off business cards with code: XDF7WF7Z (excludes Essential Business Cards)`
      : locale === 'en-gb'
        ? `You're all set to print! Get 20% off business cards with code: XDF7WF7Z`
        : 'You’re all set to print! Get 50 Standard Business Cards for $10 with code: NEWLOOK10'

  return (
    <div className={classes.promoBarContainer}>
      <FlexBox
        fontWeight="bold"
        fontSize="small"
        className={classes.promoBarFlexBox}
        paddingX={7}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        darkMode={true}
      >
        <span>🎉 {promoMessage} 🎉</span>
      </FlexBox>
    </div>
  )
}

export default PromoBar

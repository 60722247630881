// @ts-nocheck
import { IdentityProvider, useUserContext, useIdentity, useLogger } from '@vp/ubik-context'
import { useState, useEffect } from 'react'
import { loadBrandProfile } from '../api/brand'
import i18n from '../i18n/i18n'
import { logger } from '../utilities/logger'
import PromoBar from './PromoBar/PromoBar'
import { getPrimaryLogoMakerBrandProfileLogoId } from '../utilities/brandDataParser'

export interface Props {
  brandid: string
  locale?: string
}

export const PromoBarFragment = (props: Props) => {
  // const { t } = useTranslation()
  const createAuthConfig = (culture: string, isDevelopment = false) => ({
    culture,
    developmentMode: isDevelopment,
    options: { requireSession: true, customText: 'account.signIn' },
  })

  const locale = useUserContext().locale

  const logger = useLogger()
  logger.log('Rendering Promo bar fragment for a locale', locale)

  i18n.changeLanguage(locale)

  const showPromoBar = ['en-us', 'en-ca', 'en-au', 'en-gb'].includes(locale)

  return showPromoBar ? (
    <>
      <IdentityProvider auth={createAuthConfig(locale)}>
        <PromoBarWrapper brandid={props.brandid} locale={locale} />
      </IdentityProvider>
    </>
  ) : (
    <></>
  )
}

export const PromoBarWrapper = (props: Props) => {
  const { isIdentityIntialized, isSignedIn, auth } = useIdentity()
  const [isLogoMakerPrimaryLogo, setIsLogoMakerPrimaryLogo] = useState<boolean>(false)

  useEffect(() => {
    const fetchBrandProfile = async () => {
      try {
        const authHead = auth?.getAuthorizationHeader()
        if (props.brandid && authHead) {
          const { brandProfile } = await loadBrandProfile(authHead, props.brandid)
          const primaryLogoMakerBrandProfileId = getPrimaryLogoMakerBrandProfileLogoId(brandProfile)
          setIsLogoMakerPrimaryLogo(primaryLogoMakerBrandProfileId !== null)
          return
        }
      } catch (error) {
        logger.error('BrandProfile Error: Error fetching brand profile:', error)
        if (!isSignedIn) {
          auth?.signIn()
          return
        }
      }
    }

    fetchBrandProfile()
  }, [auth])

  return isLogoMakerPrimaryLogo ? <PromoBar locale={props.locale} /> : <></>
}
